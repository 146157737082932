import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { CardMenu, Button } from '@fabric/ui';
import {
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Modal,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { RootState } from 'app/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { isEqual } from 'lodash';
import { encryptSecret, createEntity, updateEntity } from 'app/services/entityService';
import { useHistory } from 'react-router-dom';
import { getErrorMessage } from '@fabric/ui/src/utils';
import { enqueueSnackbar } from '../../redux/notifier/notifier.slice';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '36px 40px 0px 35px',
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modal: {
    top: 0,
    right: 0,
    position: 'fixed',
    width: '640px',
    height: '100%',
    background: '#FFFFFF',
    overflow: 'scroll',
  },
  modalHeader: {
    fontFamily: 'Open Sans',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'center',
  },
  modalMain: {
    marginTop: '20px',
    height: 'calc(100% - 110px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  formField: {
    marginTop: '20px',
  },
  cancelBtn: {
    color: '#1c1b1b',
    background: '#c2c1c1',
  },
}));

const getUnmodifiableFields = (_entityType: string, schema: any) => {
  if (schema?.required?.includes('partner_id')) {
    return ['id', 'created_at', 'updated_at', 'tenant_id', 'name', 'type', 'labels'];
  }

  return ['id', 'created_at', 'updated_at', 'tenant_id', 'partner_id', 'name', 'type', 'labels'];
};

const SECRET_REGEX = '^(arn:.+)|(kms:[a-z0-9-]+:.+)|(plain:.*)$';
const SECRET_CHARACTER_LIMIT = 4096;

const EntityCreate: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showEncryptionForm, setShowEncryptionForm] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [secretPlainText, setSecretPlainText] = useState<string>('');
  const [secretCipherText, setSecretCipherText] = useState<string>('');
  const [secretEncryptError, setSecretEncryptError] = useState<string>('');
  const [confirmation, setConfirmation] = useState<string>('');
  const { currentEntity } = useSelector((state: RootState) => state && state.entity);
  const confirmText = currentEntity?.data ? 'confirm update' : 'confirm create';
  const unmodifiableFields = getUnmodifiableFields(currentEntity?.type as string, currentEntity?.schema);

  const getObjectValue = (fieldSchema: any, value?: any) => {
    if (value && typeof value === 'object') {
      return JSON.stringify(value, null, 2);
    }

    const placeholder: Record<string, any> = {};
    Object.keys(fieldSchema.properties || {}).forEach((key) => {
      if (fieldSchema.properties[key].type === 'object') {
        placeholder[key] = {};
      } else {
        placeholder[key] = '';
      }
    });

    return JSON.stringify(placeholder, null, 2);
  };

  const getArrayValue = (value?: any) => {
    if (Array.isArray(value)) {
      return JSON.stringify(value, null, 2);
    }

    return JSON.stringify([], null, 2);
  };

  const buildInitialValues = (schema: any) => {
    const currentValues = currentEntity?.data;
    const initialValues: Record<string, any> = {};

    Object.keys(schema.properties).forEach((key) => {
      if (unmodifiableFields.includes(key)) {
        return;
      }

      if (schema.properties[key].type === 'object') {
        initialValues[key] = getObjectValue(schema.properties[key], currentValues?.[key]);
      } else if (schema.properties[key].type === 'array') {
        initialValues[key] = getArrayValue(currentValues?.[key]);
      } else if (schema.properties[key].type === 'number' && currentValues?.[key] !== undefined) {
        if (typeof currentValues?.[key] === 'number') {
          initialValues[key] = currentValues?.[key];
        } else {
          initialValues[key] = parseInt(currentValues?.[key], 10);
        }
      } else if (schema.properties[key].type === 'boolean' && currentValues?.[key] !== undefined) {
        initialValues[key] = currentValues?.[key].toString();
      } else if (currentValues?.[key] !== undefined) {
        initialValues[key] = currentValues?.[key];
      }
    });

    return initialValues;
  };

  const buildValidationSchema = (schema: any) => {
    const validationSchema: Record<string, any> = {};
    const requiredFields = schema.required || [];
    Object.keys(schema.properties).forEach((key) => {
      if (unmodifiableFields.includes(key)) {
        return;
      }

      if (schema.properties[key].type === 'object') {
        validationSchema[key] = yup.string().test('is-json', 'Invalid Object', (value) => {
          if (!value) {
            return true;
          }

          try {
            JSON.parse(value);
            return true;
          } catch (e) {
            return false;
          }
        });
      } else if (schema.properties[key].type === 'array') {
        validationSchema[key] = yup.string().test('is-json-array', 'Invalid Array', (value) => {
          if (!value) {
            return true;
          }

          try {
            return Array.isArray(JSON.parse(value));
          } catch (e) {
            return false;
          }
        });
      } else if (schema.properties[key].type === 'number') {
        validationSchema[key] = yup.number().typeError('Enter a valid number');
      } else if (schema.properties[key].type === 'boolean') {
        validationSchema[key] = yup.string().test('is-boolean', 'Invalid Boolean', (value) => {
          return value === 'true' || value === 'false';
        });
      } else {
        validationSchema[key] = yup.string();
        if (schema.properties[key].pattern === SECRET_REGEX) {
          validationSchema[key] = validationSchema[key].matches(
            new RegExp(schema.properties[key].pattern),
            'Invalid Secret Data',
          );
        }
      }

      if (requiredFields.includes(key)) {
        validationSchema[key] = validationSchema[key].required(`${key} is required`);
      }
    });

    return yup.object(validationSchema);
  };

  const formik = useFormik({
    initialValues: buildInitialValues(currentEntity!.schema),
    validationSchema: buildValidationSchema(currentEntity!.schema),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      setOpenConfirmDialog(true);
    },
  });
  const { values: formValues, errors, handleSubmit } = formik;

  const handleConfimrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setConfirmation(e.target.value);
  };

  const handleEntitySubmit = () => {
    if (confirmation !== confirmText) {
      return;
    }

    if (currentEntity?.data) {
      const entityData = getModifiedData(formValues);
      if (Object.keys(entityData).length === 0) {
        history.push('/entity');
        return;
      }

      updateEntity(
        currentEntity!.data.id,
        entityData,
        currentEntity!.tenant.tenantId,
        currentEntity!.tenant.tenantDelegateToken!,
      )
        .then(() => {
          history.push('/entity');
        })
        .catch((e) => {
          console.error(e);
          const key = new Date().getTime() + Math.random();
          dispatch(
            enqueueSnackbar({
              notification: {
                key,
                message: getErrorMessage(e),
                options: {
                  key: key,
                  variant: 'error',
                },
              },
            }),
          );
        });
    } else {
      const entityData = {
        ...parseValuesWithSchema(currentEntity!.schema, formValues),
        type: currentEntity!.type,
        name: `${currentEntity!.type} ${currentEntity!.tenant.tenantSlug}`,
      };
      createEntity(entityData, currentEntity!.tenant.tenantId, currentEntity!.tenant.tenantDelegateToken!)
        .then(() => {
          history.push('/entity');
        })
        .catch((e) => {
          console.error(e);
          const key = new Date().getTime() + Math.random();
          dispatch(
            enqueueSnackbar({
              notification: {
                key,
                message: getErrorMessage(e),
                options: {
                  key: key,
                  variant: 'error',
                },
              },
            }),
          );
        });
    }
  };

  const handleSecretModalClose = () => {
    setShowEncryptionForm(false);
    setSecretPlainText('');
    setSecretCipherText('');
    setSecretEncryptError('');
  };

  const onEncryptionClicked = () => {
    setShowEncryptionForm(true);
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
    setConfirmation('');
  };

  const handleFormCancel = () => {
    history.push('/entity');
  };

  const handleSecretPlaintextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSecretPlainText(e.target.value);
  };

  const handleSecretEncrypt = () => {
    if (!secretPlainText) {
      return;
    }

    if (secretPlainText.length > SECRET_CHARACTER_LIMIT) {
      setSecretEncryptError(`Secret data cannot be more than ${SECRET_CHARACTER_LIMIT} characters`);
      return;
    }

    setSecretEncryptError('');
    encryptSecret(secretPlainText, currentEntity!.tenant.tenantId, currentEntity!.tenant.tenantDelegateToken!)
      .then((ciphertext) => {
        setSecretCipherText(ciphertext);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSecretCopy = () => {
    navigator.clipboard.writeText(secretCipherText);
    handleSecretModalClose();
  };

  const getEncryptionHelperProps = (fieldSchema: any) => {
    if (fieldSchema.type === 'object' || (fieldSchema.type === 'string' && fieldSchema.pattern === SECRET_REGEX)) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" color="primary" onClick={onEncryptionClicked}>
              <VpnKeyIcon />
            </IconButton>
          </InputAdornment>
        ),
      };
    }

    return undefined;
  };

  const renderField = (field: string, fieldSchema: any, requiredFields: string[]) => {
    if (fieldSchema.type === 'string') {
      return (
        <TextField
          fullWidth
          id={field}
          name={field}
          label={field + (requiredFields.includes(field) ? ' (*)' : '')}
          error={!!errors[field]}
          helperText={errors[field]}
          variant="outlined"
          value={formValues[field]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          className={classes.formField}
          InputProps={getEncryptionHelperProps(fieldSchema)}
        />
      );
    } else if (fieldSchema.type === 'number') {
      return (
        <TextField
          fullWidth
          id={field}
          name={field}
          label={field + (requiredFields.includes(field) ? ' (*)' : '')}
          error={!!errors[field]}
          helperText={errors[field]}
          variant="outlined"
          value={formValues[field]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="number"
          className={classes.formField}
        />
      );
    } else if (fieldSchema.type === 'boolean') {
      return (
        <TextField
          fullWidth
          id={field}
          name={field}
          label={field + (requiredFields.includes(field) ? ' (*)' : '')}
          error={!!errors[field]}
          helperText={errors[field]}
          variant="outlined"
          value={formValues[field]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          select
          className={classes.formField}
        >
          <MenuItem value="true">true</MenuItem>
          <MenuItem value="false">false</MenuItem>
        </TextField>
      );
    } else if (fieldSchema.type === 'object') {
      return (
        <TextField
          fullWidth
          id={field}
          name={field}
          label={field + (requiredFields.includes(field) ? ' (*)' : '')}
          error={!!errors[field]}
          helperText={errors[field]}
          variant="outlined"
          value={formValues[field]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          multiline
          minRows={10}
          inputProps={{ style: { resize: 'both' } }}
          className={classes.formField}
          InputProps={getEncryptionHelperProps(fieldSchema)}
        />
      );
    } else if (fieldSchema.type === 'array') {
      return (
        <TextField
          fullWidth
          id={field}
          name={field}
          label={field + (requiredFields.includes(field) ? ' (*)' : '')}
          error={!!errors[field]}
          helperText={errors[field]}
          variant="outlined"
          value={formValues[field]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          multiline
          minRows={5}
          inputProps={{ style: { resize: 'both' } }}
          className={classes.formField}
        />
      );
    }

    return <React.Fragment />;
  };

  const renderForm = (schema: any) => {
    const fields = Object.keys(schema.properties);
    const requiredFields = schema.required || [];

    return fields.map((field) => {
      if (unmodifiableFields.includes(field)) {
        return <React.Fragment />;
      }

      return renderField(field, schema.properties[field], requiredFields);
    });
  };

  const parseValuesWithSchema = (schema: any, values: any): Record<string, any> => {
    const parsedValues: Record<string, any> = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        return;
      }

      if (schema.properties[key].type === 'object' || schema.properties[key].type === 'array') {
        try {
          parsedValues[key] = JSON.parse(values[key]);
        } catch (e) {
          console.error(e);
        }
      } else if (schema.properties[key].type === 'boolean') {
        parsedValues[key] = values[key] === 'true';
      } else {
        parsedValues[key] = values[key];
      }
    });

    return parsedValues;
  };

  const getModifiedData = (values: any) => {
    const parsedValues = parseValuesWithSchema(currentEntity!.schema, values);
    const modifiedData: Record<string, any> = {};
    Object.keys(values).forEach((key) => {
      if (unmodifiableFields.includes(key)) {
        return;
      }

      if (!isEqual(parsedValues[key], currentEntity!.data[key])) {
        modifiedData[key] = parsedValues[key];
      }
    });

    return modifiedData;
  };

  const renderConfirmHelpText = () => {
    if (currentEntity?.data) {
      const modifiedData = getModifiedData(formValues);

      return (
        <DialogContentText>
          This will update existing {currentEntity!.type} Entity for Tenant for the following fields:
          <ul>
            {Object.keys(modifiedData).map((key) => {
              return <li>{key}</li>;
            })}
          </ul>
          Please type "{confirmText}" to proceed.
        </DialogContentText>
      );
    } else {
      return (
        <DialogContentText>
          This will create new {currentEntity!.type} Entity for Tenant. Please type "{confirmText}" to proceed.
        </DialogContentText>
      );
    }
  };

  return (
    <div className={classes.root}>
      {currentEntity && (
        <CardMenu title={`${currentEntity?.data ? 'Update' : 'Create'} ${currentEntity!.type}`} subtitle="">
          <form onSubmit={handleSubmit}>
            {renderForm(currentEntity!.schema)}
            <div className={classes.cardFooter}>
              <Button variant="contained" type="submit" className={classes.formField}>
                {currentEntity?.data ? 'Update' : 'Create'}
              </Button>
              <Button type="button" className={`${classes.cancelBtn} ${classes.formField}`} onClick={handleFormCancel}>
                Cancel
              </Button>
            </div>
          </form>
        </CardMenu>
      )}

      {showEncryptionForm && (
        <Modal
          open={showEncryptionForm}
          className={classes.modal}
          style={{ inset: 'auto' }}
          onClose={handleSecretModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={clsx(classes.root, classes.modal)}>
            <Box className={classes.modalHeader}>KMS Encryption Utility</Box>
            <Box className={classes.modalMain}>
              <TextField
                fullWidth
                id="plaintext"
                name="plaintext"
                label="plaintext"
                variant="outlined"
                error={!!secretEncryptError}
                helperText={secretEncryptError}
                className={classes.formField}
                multiline
                minRows={10}
                inputProps={{ style: { resize: 'both' } }}
                value={secretPlainText}
                onChange={handleSecretPlaintextChange}
              />
              <Button variant="contained" type="submit" className={classes.formField} onClick={handleSecretEncrypt}>
                Encrypt
              </Button>
              <TextField
                fullWidth
                id="ciphertext"
                name="ciphertext"
                label="ciphertext"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                multiline
                minRows={10}
                value={secretCipherText}
                inputProps={{ style: { resize: 'both' } }}
                className={classes.formField}
              />
              <Button variant="contained" type="submit" className={classes.formField} onClick={handleSecretCopy}>
                Copy
              </Button>
            </Box>
          </div>
        </Modal>
      )}
      {openConfirmDialog && (
        <Dialog open={openConfirmDialog}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            {renderConfirmHelpText()}
            <TextField
              fullWidth
              id="confirmText"
              name="confirmText"
              placeholder={confirmText}
              variant="outlined"
              value={confirmation}
              onChange={handleConfimrationChange}
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" className={classes.cancelBtn} onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" onClick={handleEntitySubmit} disabled={confirmation !== confirmText}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default EntityCreate;
